class Producer

    constructor: ->
        @registerEvents()
        @fixHeight = new FixHeight
        @map = new VenueMap('producer')

    registerEvents: ->
        $(document).ready =>
            @fixHeight.fix($('section.shows-list .show .details'))

        $(window).load =>
            @fixHeight.fix($('section.shows-list .show .details'))

        $(window).resize =>
            @fixHeight.fix($('section.shows-list .show .details'))

        $(document).on 'shown.bs.modal', '#eventMapModal', (e) =>
            @map.initMap()
