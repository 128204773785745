class FAQ

    constructor: ->
        @registerEvents()

    registerEvents: ->
        $(document).ready =>
            @masonry()

    masonry: ->
        $('ul.faqs').masonry()
