class window.FixHeight

    constructor: ->

    fix: (selector) ->
        # Default the min to 0
        min = 0
        # Get the elements to resize
        $elements = $(selector)
        # Reset their height
        $elements.css
            height: 'auto'
        # If they are on greater than mobile viewport
        if $(window).width() > 767
            # Loop the elements and set their height to the
            # maximum element height
            $elements.each (i, el) ->
                if $(el).outerHeight() > min
                    min = $(el).outerHeight()
            .css
                height: min
