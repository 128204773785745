class VenueMap

    constructor: (@key = 'venue') ->
        @registerReadyEvent()

    registerReadyEvent: ->
        # This would usually tell the window to generate the map,
        # but because it's in a hidden modal it wont
        # size correctly. So let's NoOp it.
        window.mapReady = ->
            return

    initMap: =>
        # Only init once
        if @map
            return
        # Set the center for use elsewhere
        @center =
            lat: parseFloat(YBO[@key].lat)
            lng: parseFloat(YBO[@key].long)
        # Set up the map
        @map = new google.maps.Map $('#venue-map')[0],
            center:           @center
            zoom:             12
            scrollwheel:      false
            disableDefaultUI: true

        @addInitialMarker()
        @registerEvents()

    addInitialMarker: ->
        # Set up the marker icon
        image =
            url:    '/images/frontend/shared/icons/map-marker.png'
            size:   new google.maps.Size(20, 30)
            origin: new google.maps.Point(0, 0)
            anchor: new google.maps.Point(0, 15)

        shape =
            coords: [10, 29, 7, 26, 1, 11, 0, 8, 3, 3, 10, 0, 15, 2, 20, 7, 20, 12, 13, 26]
            type:   'poly'
        # Add the marker
        new google.maps.Marker
            position:  @center
            map:       @map
            title:     YBO[@key].name
            animation: google.maps.Animation.DROP
            icon:      image
            shape:     shape

    registerEvents: ->
        if google and @map
            google.maps.event.addDomListener window, 'resize', =>
                @map.setCenter(@center)
