class Global

    constructor: ->
        @registerEvents()

    registerEvents: ->
        # Setup global elements
        $(document).ready =>
            @documentReady()

        $(window).scroll @sizeHeader

        # Setup parallax element if it exists
        if $('[data-parallax]').length
            $(window).scroll @onWindowScrollParallax

        if $('.header-notices').children().length
            @sizeMainPaddingEvents()

    sizeMainPaddingEvents: ->
        @sizeMainPadding()
        $(document).ready(@sizeMainPadding)
        $(window).resize(@sizeMainPadding)

    sizeMainPadding: ->
        headerHeight = $('header').outerHeight()
        $('main').css
            'padding-top': "#{headerHeight}px"

    # Document is ready, add the class
    documentReady: ->
        @placeholders()
        # Add delay as a fix for loading lag
        setTimeout (->
            $('body').addClass 'ready'
        ), 10
        $('[data-toggle="popover"]').popover()
        @addTokenHeaders()
        @YBOTooltips()
        @datepickers()

    datepickers: ->
        __defaults =
            nextText:   '<i class="fa fa-chevron-right"></i>'
            prevText:   '<i class="fa fa-chevron-left"></i>'
            dateFormat: 'dd/mm/yy'
            yearRange:  "-100:+0"

        $("[data-datepicker]").each (i, el) ->
            options = $(el).data('datepicker')

            if !_.isObject(options)
                options = {}

            $(el).datepicker($.extend(true, __defaults, options))

    addTokenHeaders: ->
        if !YBO? or !App.token
            return
        # Add our token header
        $.ajaxSetup
            headers:
                'X-CSRF-TOKEN': App.token

    # Setup placeholders for legacy browsers
    placeholders: ->
        $('input, textarea').placeholder()

    sizeHeader: ->
        $('body').toggleClass 'thin-nav', ($(window).scrollTop() > 74)

    # Setup parallax based on data attributes
    onWindowScrollParallax: ->
        $parallax = $('[data-parallax]')
        # Get the data passed in
        data = $parallax.data 'parallax'
        # The speed is a ratio of the distance scrolled
        # e.g. 0.5 would be 50% the distance of the page scroll
        speed = data.speed

        scrolled = $(window).scrollTop()

        amount = Math.ceil(speed * scrolled)

        if amount < 0
            amount = 0

        $parallax.css
            'transform':  "translate3d(0, #{amount}px, 0)"

    YBOTooltips: ->
        $('[data-ybo-tooltip]').popover
            toggle:    "popover"
            placement: "top"
            trigger:   "click hover focus"
            html:      true
            content:   ->
                return $(this).next('script').html()
