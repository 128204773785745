Vue.component 'minicart',

    template: '#mini-cart-template'

    data: ->
        return {
            App: window.App
            accepted:false
        }

    computed:
        hasCart: ->
            return !!this.App.cart

        cartItemsTotal: ->
            if !this.hasCart
                return 0
            return _.size(this.App.cart.items)

        cartItemsTotalPrice: ->
            if !this.hasCart
                return "£0.00"
            return "£#{this.App.cart.grandTotal.toFixed(2)}"
