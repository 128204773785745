# Turn Vue debug on for development
Vue.config.debug = false
Vue.config.silent = false
Vue.config.warnExpressionErrors = false

Global       = new Global
Template     = new Page({ type: 'template' })
Page         = new Page({ type: 'page' })
Nav          = new Nav
ConfirmModal = new ConfirmModal

window.SeatingPlanHelpers = new SeatingPlanHelpers

if $('.modal').length
    Modal = new Modal

if $('[data-scrollto]').length
    new ScrollTo

# Pages
if Template.is 'homepage'
    new Homepage

if Page.is 'auth'
    new Auth

if Template.is 'about'
    new About

if Template.is 'buytickets'
    new BuyTickets

if Template.is 'selltickets'
    new SellTickets

if Template.is 'contact'
    new Contact

if Template.is 'faq'
    new FAQ

if Template.is 'genericpage'
    new GenericPage

if Page.is 'event'
    new Event

if Page.is 'producer'
    new Producer

if Page.is 'cart'
    new Cart

# Booking process
if Page.is 'bookyour-details'
    new BookYourDetails

if Page.is 'bookpayment'
    new BookPayment

if Page.is 'bookconfirmation'
    new BookConfirmation
