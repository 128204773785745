class Validator

    constructor: ->

    # Perform form validation on the form
    validateForm: (e) ->
        $form = $(e.currentTarget)
        # Find fields to validate
        $toValidate = $form.find('[data-validate]')
        # Perform validation on each
        $toValidate.each (i, el) =>
            @validateInput(el)
        # Prevent submission if errors are found
        if @hasErrors(e)
            e.preventDefault()
            return false

    # Perform form validation on single input
    validateInput: (el) ->
        $input = $(el)
        $inputContainer = $(el).closest('.input')
        # 'Required' validation
        if $input.data('validate') == 'required'
            # Incorrect values for this validation are '0', '' and null
            if $input.val() is '0' or $input.val() is '' or $input.val() is null
                $inputContainer.addClass 'error'
            else
                $inputContainer.removeClass 'error'

    # Check if the form has errors
    hasErrors: (e) ->
        $form = $(e.currentTarget)
        # Return how many errors were found. 0 would be false
        return $form.find('.input.error').length
        