class Auth

    constructor: ->
        @registerEvents()

    registerEvents: ->
        @sizeContent()

        $(document).ready @sizeContent
        $(window).resize @sizeContent
        $(window).load @sizeContent

    sizeContent: ->
        # Should we size?
        $toSize = $('section.auth .o')
        $toSize.css
            height: false

        if $(window).width() >= 768
            newHeight = $(window).height() - $('header').outerHeight() - $('footer').outerHeight()

            if newHeight > $toSize.find('.container').outerHeight()
                $toSize.css
                    height: newHeight
