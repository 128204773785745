class Page

    constructor: (@extended = {}) ->
        @isIn = false
        @config =
            type:      'page'
            component: ''
        # If a custom config has been defined
        if _.size(@extended)
            # Merge overrides
            $.extend @config, @extended

    # Check if page is active
    is: (slug) ->
        # Returns true or false
        return $('body').hasClass("#{@config.type}-#{@config.component}#{slug}")

    in: (slugs) ->
        # Reset the bool
        @isIn = false
        # Make sure slugs is an array
        slugs = if slugs instanceof Array then slugs else [slugs];
        # Check each and return as the normal function
        for slug in slugs
            do (slug) =>
                if @is(slug) then @isIn = true

        return @isIn

    segment: (id) ->
        # Get segments
        segments = window.location.pathname.split('/')
        # If segment is set
        if segments[id]?
            # Return requested segment
            return segments[id]
        else
            # Return false
            return false

    segmentMatch: (id, match) ->
        # Shorthand segment check
        return (@segment(id) == match)
