class Event

    constructor: ->
        @map = new VenueMap
        @registerEvents()
        @registerComponent()

    registerComponent: ->
        @component = new Vue
            el: 'body'

            data:
                event:               App.event
                isSubmitting:        false
                selectedPerformance: null
                formData:
                    buying_for: ''
                    email: ''
                    phone: ''
                    address:
                        house: ''
                        address_1: ''
                        address_2: ''
                        city:      ''
                        county:    ''
                        postcode:  ''
                    date:        false
                    time:        false
                    full:        0
                    member:      0
                    bundle:      0
                    concessions: 0
                    complimentary: 0
                    referral:    0

                addressOptions:          {}
                selectedAddress:         {}
                performingAddressLookup: false
                performingUserLookup: false
                userFound: false

                postData: {}

                emptySeatingPlan:
                    id:    null
                    name:  'New Seating Plan'
                    areas: {}

                seatingPlan:         App.event.seatingPlan
                originalSeatingPlan: App.event.seatingPlan
                selectedSection:     false
                heightSet:           false
                seatsAvailable:      0
                seatsAllocated:      0

                grid:
                    width:  30
                    height: 16

            ready: ->
                $(document).on 'shown.bs.modal', '#seatPickerModal', (e) =>
                    @seatPickerOpened()

                $(document).on 'hidden.bs.modal', '#seatPickerModal', (e) =>
                    @doSeatingPlanCleanup()

            watch:
                'selectedAddress': (val) ->
                    if val.address_components
                        this.setAddress(val)

                'formData.date': (val, oldVal) ->
                    if val.date != oldVal.date
                        this.$set('selectedPerformance', null)

                'selectedSection': (val, oldVal) ->
                    $('#seatingPlanStages').data('owlCarousel').goTo(!!@selectedSection)

                'selectedPerformance': (val, oldVal) ->
                    this.formData.time = if _.isObject(val) then val.id else val

            computed:
                oldSeatingPlan: ->
                    if(this.seatingPlan.created)
                        newPlanDate = new Date('12/04/2018')
                        thisPlanDate = new Date(this.seatingPlan.created.date)
                        return thisPlanDate < newPlanDate
                    return true
                selectionRows: ->
                    height = this.selectedSection.grid_height
                    width = this.selectedSection.grid_width
                    selectedRows = this.selectedSection.rows.filter (item) -> item.number <= height

                    for s in selectedRows
                        s.seats = s.seats.filter (item) -> item.number <= width

                    return selectedRows

                addressFieldsShouldShow: ->
                    return !!this.selectedAddress.address_components || this.userFound

                hasReferralOptions: ->
                    return !_.isEmpty(this.event.referralOptions)

                canSubmitForm: ->
                    return (this.formData.date and this.formData.time and !!this.seatsAvailable)

                seatPickerShouldShow: ->
                    return (this.event.hasSeatingPlan and this.seatingPlan)

                seatingPlanHasRendered: ->
                    return this.heightSet

                seatsAvailable: ->
                    available = 0
                    for key, value of @event.priceOptions
                        if @bookingTypeEnabled(key)
                            if key is "bundle"
                                bundleTickets = parseInt(@event.priceOptions.bundle.adults) + parseInt(@event.priceOptions.bundle.children)
                                available = available + (bundleTickets * parseInt(this.formData[key]))
                            else
                                if this.formData[key]
                                    val = this.formData[key]
                                # This is awful, don't ever do this.
                                else if this.formData["#{key}s"]
                                    val = this.formData["#{key}s"]
                                else
                                    val = 0
                                available = available + parseInt(val)
                    return available

                seatsRemaining: ->
                    return @seatsAvailable - @seatsAllocated

                allSeatsAreAllocated: ->
                    return !@seatsRemaining

                initialTimeValue: ->
                    if !this.formData.date || !this.formData.date.performances
                        return "Please select a date"

                    if this.formData.date && this.formData.date.performances
                        return "Select time"

                    return "fallback"

                timeOptions: ->
                    if !(this.formData.date && this.formData.date.performances)
                        return []

                    return this.formData.date.performances

            filters:
                humanDate: (date) ->
                    return moment(date).format('dddd Do MMMM YYYY')

                humanTime: (time) ->
                    date = this.formData.date.date
                    start = moment("#{date} #{time.start}").format('HH:mma')
                    end = moment("#{date} #{time.end}").format('HH:mma')
                    return "#{start} - #{end}"

                seatName: (seat) ->
                    return seat.name

            methods:
                getAddress: ->
                    this.performingAddressLookup = true
                    geocoder = new google.maps.Geocoder()
                    geocoder.geocode({
                        address: this.formData.address.postcode
                        componentRestrictions:
                            country: 'GB'
                    }, this.getAddressResults)

                getAddressResults: (results, status) ->
                    this.performingAddressLookup = false
                    if (status == 'OK')
                        this.addressOptions = results

                        this.selectedAddress = this.addressOptions[0]
                    else
                        alert("Geocode was not successful for the following reason: #{status}")

                setAddress: (address) ->
                    that = this
                    this.getAddressComponent('route', (val) ->
                        that.formData.address.address_1 = val
                    )
                    this.getAddressComponent(['locality', 'postal_town'], (val) ->
                        that.formData.address.city = val
                    )
                    this.getAddressComponent('administrative_area_level_2', (val) ->
                        that.formData.address.county = val
                    )

                getAddressComponent: (component, callback = ->) ->
                    if typeof component is 'string'
                        component = [component]

                    $.each this.selectedAddress.address_components, (i, c) ->
                        $.each component, (ii, type) ->
                            if $.inArray(type, c.types) > -1
                                callback(c.long_name)
                                return false
                    return false

                seatPickerOpened: ->
                    this.seatingPlan = this.formatSeatingPlan()
                    this.$nextTick ->
                        $('[data-toggle="tooltip"]').tooltip()
                    @seatSizing = setInterval (=>
                        this.sizeSeatingPlanArea()
                    ), 1000

                doSeatingPlanCleanup: ->
                    @isSubmitting    = false
                    @heightSet       = false
                    @seatingPlan     = $.extend(true, {}, @originalSeatingPlan)
                    @selectedSection = false
                    @seatsAvailable  = 0
                    @seatsAllocated  = 0

#               called when on seating plan modal add to cart button
                addToCartWithSeats: ->

                    @sendRequest()

                backToSections: ->
                    @selectedSection = false

                selectSeat: (seat) ->
                    if !@isSeatAvailable(seat)
                        return

                    if seat.selected
                        _.remove @postData.seats, (val) =>
                            return val == seat.id
                        @seatsAllocated--
                        seat.selected = false
                    else
                        if @allSeatsAreAllocated
                            return false
                        @postData.seats.push seat.id
                        @seatsAllocated++
                        seat.selected = true

                selectSection: (section) ->
                    this.selectedSection = section

                getPrice: (type) ->
                    if this.selectedPerformance.prices[type]
                        return this.selectedPerformance.prices[type]

                hasPerformance: ->
                    return !! this.selectedPerformance

                bookingTypeEnabled: (key) ->
                    if !this.formData.time
                        return false
                    return this.hasPerformance() && (if key == 'bundle' then this.selectedPerformance.prices.bundle else true)

                sizeSeatingPlanArea: (force = false) ->
                    $seatingPlan = $('.seating-plan-area')
                    $sections = $seatingPlan.children()
                    minHeight = 0
                    minWidth = 0

                    farBottom = 0

                    $sections.each (i, el) ->
                        sectionHeight = Math.ceil($(el)[0].getBoundingClientRect().height)
                        farBottom = Math.ceil($(el).position().top + sectionHeight)
                        # If it's greater than our max, overwrite it
                        if farBottom > minHeight
                            minHeight = farBottom

                    $seatingPlan.height(minHeight + 50)

                    $sections.each (i, el) ->
                        sectionWidth = Math.ceil($(el)[0].getBoundingClientRect().width)
                        farRight = Math.ceil($(el).position().left + sectionWidth)
                        # If it's greater than our max, overwrite it
                        if farRight > minWidth
                            minWidth = farRight

                    $seatingPlan.width(minWidth)

                    if !force and $seatingPlan.outerWidth() >= minWidth and $seatingPlan.outerHeight() >= minHeight
                        @heightSet = true
                        clearInterval(@seatSizing)
                        this.$nextTick ->
                            $(window).trigger('resize')

                resetForm: ->
                    @selectedPerformance  = false
                    @formData =
                        buying_for: ''
                        email: ''
                        phone: ''
                        address:
                            house: ''
                            address_1: ''
                            address_2: ''
                            city:      ''
                            county:    ''
                            postcode:  ''
                        time:        false
                        date:        false
                        full:        0
                        member:      0
                        bundle:      0
                        concessions: 0
                        complimentary: 0

#               called when on ticket quantity page submit button
                addToCart: (e) ->
                    @isSubmitting = true

                    @form = $(e.target)
                    @postData =
                        performance_id: @formData.time

                    @postData.bookings = {}
                    if @formData.concession
                        @postData.bookings.concession = @formData.concession

                    if @formData.complimentary
                        @postData.bookings.complimentary = @formData.complimentary

                    if @formData.member
                        @postData.bookings.member = @formData.member

                    if @formData.full
                        @postData.bookings.full = @formData.full

                    if @formData.bundle
                        @postData.bookings.bundle = @formData.bundle

                    if @formData.referral
                        @postData.referral = @formData.referral

                    if @formData.buying_for
                        @postData.buying_for = @formData.buying_for

                    if @formData.address
                        @postData.address = @formData.address

#                    if @formData.ticket_holder_email
#                        @postData.ticket_holder_email = @formData.ticket_holder_email
                    if @formData.email
                        @postData.email = @formData.email

                    if @formData.phone
                        @postData.phone = @formData.phone

#                    if @event.hasMultiplePriceBands && App.cart != undefined
#                        swal({
#                            type:               'warning'
#                            title:              "This event offers tickets at different price bands. You must first pay for the items in your cart before choosing tickets at a different price."
#                            html:               true
#                            showConfirmButton:  true
#                            confirmButtonText:  'CHECKOUT'
#                            confirmButtonColor: '#e3ba2d'
#                            showCancelButton:   true
#                            cancelButtonText:   'EMPTY CART'
#                            cancelButtonColor:  '#319eca'
#                        }, (val) =>
#                            if val
#                                window.location = App.urls.cart
#                        )
                    if @event.hasSeatingPlan
                        @postData.seats = []
                        @getSeatingPlan()
                        return

                    @sendRequest()

                getExistingUser: ->
                    this.performingUserLookup = true
                    $request = $.ajax
                        url: [App.urls.get_existing_user, this.formData.email].join('/')
                        type: 'GET'

                    $request.done (data) =>
                        this.performingUserLookup = false
                        this.formData.buying_for = data.details.name
                        this.formData.address = data.details.address
                        this.formData.phone = data.details.phone
                        this.userFound = true

                    $request.fail (error ) =>
                        this.performingUserLookup = false
                        this.formData.buying_for = ''
                        this.formData.address = ''
                        this.formData.phone = ''
                        this.userFound = false
                        console.log(error.statusText)

                getSeatingPlan: ->
                    $request = $.ajax
                        url:  [App.urls.get_seating_plan, this.formData.time].join('/')
                        type: 'GET'

                    $request.done (data) =>
                        this.seatingPlan = data
                        this.$nextTick ->
                            $('#seatPickerModal').modal 'show'
                            @setupCarousel()

                sendRequest: () ->
                    $request = $.ajax
                        url:  @form.attr 'action'
                        type: 'POST'
                        data: @postData

                    $request.done (data, textStatus, jqXHR) =>
                        $('#seatPickerModal').modal('hide')
                        Vue.set(window.App, 'cart', data.data.cart)
                        swal({
                            type:               'success'
                            title:              "The tickets you selected were successfully added to your cart!"
                            html:               true
                            showConfirmButton:  true
                            confirmButtonText:  'Go to your Cart'
                            confirmButtonColor: '#e3ba2d'
                            showCancelButton:   true
                            cancelButtonText:   'Go Back'
                            cancelButtonColor:  '#319eca'
                        }, (val) =>
                            @resetForm()
                            if val
                                window.location = App.urls.cart
                        )

                    $request.fail (jqXHR, textStatus, errorThrown) ->
                        swal("An error occurred", jqXHR.responseJSON.data.message, 'error')

                    $request.always (jqXHR, textStatus, errorThrown) =>
                        @isSubmitting = false

                # We need to modify our data so that it is
                # usable in our application
                formatSeatingPlan: ->
                    seatingPlan = this.seatingPlan

                    # We need to add keys to our data so it's
                    # easier to stick into the grid. This is awful and i hate it.
                    areas = {}
                    # Loop the areas so we can format each row
                    for singleArea in seatingPlan.areas
                        areas["#{singleArea.id}"] = singleArea
                        # Make sure we have rows
                        if singleArea.rows
                            rows = {}
                            # Loop the rows so we can format those too
                            for singleRow in singleArea.rows
                                rows["#{singleRow.number}"] = singleRow
                                # Make sure we have seats
                                if singleRow.seats
                                    columns = {}
                                    # Loop the seats so we can format them too!
                                    for singleColumn in singleRow.seats
                                        columns["#{singleColumn.number}"] = singleColumn
                                rows["#{singleRow.number}"].seats = columns
                            areas["#{singleArea.id}"].rows = rows

                    for key, singleArea of areas
                        areas[key] = @generateSection(singleArea)

                    # Update our original data with our formatted areas
                    seatingPlan.areas = areas

                    @originalSeatingPlan = $.extend(true, {}, seatingPlan)

                    # Return a copy to prevent any issues with references.
                    return $.extend(true, {}, seatingPlan)

                generateSection: (area) ->
                    emptySection = area

                    if area
                        if area.type != 'stage'
                            emptySection.rows = @generateRows(area.rows)
                    else
                        emptySection.rows = @generateRows()

                    return emptySection

                seatClasses: (seat) ->
                    return {
                        individual_seat: seat.name != ''
                        seat_available:  @isSeatAvailable(seat)
                        seat_disabled:   seat.disabled
                        seat_selected:   seat.selected
                    }

                isSeatAvailable: (seat) ->
                    return seat.available

                generateRows: (rowsObject = []) ->
                    rows = []
                    count = @grid.height
                    while count > 0
                        if rowsObject[count]
                            row = rowsObject[count]
                            row.seats = @generateSeats(row.seats)
                        else
                            row =
                                id:     _.uniqueId()
                                name:   ''
                                seats:  @generateSeats()
                                number: count

                            if !@isEditingExisting
                                row.name = 'abcdefghijklmnopqrstuvwxyz'.charAt(count - 1).toUpperCase()

                            row.seats = @generateSeats(rowsObject.seats)

                        rows.push(row)
                        count--

                    rows = rows.reverse()

                    return rows

                generateSeats: (seatsObject = []) ->
                    console.log('generate event seats')
                    console.log(seatsObject)
                    seats = []
                    count = @grid.width
                    while count >0
                        if seatsObject[count]
                            seat = seatsObject[count]
                            seat.available = !!seat.available
                            seat.selected = false
                            seat.disabled = !!seat.disabled
                            seats.push(seat)
                        else
                            seat =
                                id:        _.uniqueId()
                                name:      ''
                                number:    count
                                disabled:  false
                                selected:  false
                                available: false

                            seats.push(seat)
                        count--
                    if(@oldSeatingPlan)
                        seats = seats.reverse()

                    return seats

                setupCarousel: ->
                    $("#seatingPlanStages").owlCarousel
                        singleItem: true
                        mouseDrag:  false
                        touchDrag:  false
                        pagination: false
                        afterMove: =>
                            $('[data-toggle="tooltip"]').tooltip()
                            setTimeout (=>
                                Modal.centerModals()
                            ), 10.1

    registerEvents: ->
        $(document).ready =>
            @documentReady()

        $(document).on 'shown.bs.modal', '#eventMapModal', (e) =>
            @map.initMap()

    documentReady: ->
        Modal.centerModals()

