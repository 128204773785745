class BuyTickets

    constructor: ->
        @fixHeight = new FixHeight
        @registerEvents()

    registerEvents: ->
        $(document).ready =>
            @fixHeight.fix($('section.content ul.events .event'))

        $(window).load =>
            @fixHeight.fix($('section.content ul.events .event'))

        $(window).resize =>
            @fixHeight.fix($('section.content ul.events .event'))

