class ScrollTo

    constructor: ->
        @events()

    events: ->
        # If the user scrolls, override the animation
        $('html, body').bind "scroll mousedown DOMMouseScroll mousewheel keyup", (e) =>
            # Make sure it was a scroll action by the user
            if (e.which > 0 or e.type == "mousedown" or e.type == "mousewheel")
                # Reset the hash
                window.location.hash = '!/'
                # Check if the scroll is being animated
                if $('html, body').is(':animated')
                    # Stop the animation
                    $('html, body').stop()
        # Listen for hash change
        $(window).on 'hashchange', =>
            # Close the nav
            Nav.closeNav()  
            @scrollTo()
        # Check hash
        $(document).ready =>
            # Add some delay to wait for other scripts to finish,
            # and it also gives it a better effect
            setTimeout ( =>
                @scrollTo()
            ), 1000

    # Scroll to waypoint
    scrollTo: ->
        # Make sure we're not already there
        # Get the has property
        hash = window.location.hash
        # Check if there's a hash to scroll to
        if hash != ''
            # Check if it exists on the page
            if @scrollHashExists(hash)
                # Animate the scroll to it
                @animateScrollTo(hash)

    # Check if hash waypoint exists on the page
    scrollHashExists: (hash) ->
        return $("[data-scrollto='#{hash}']").length

    # Animate the scroll to the hash
    animateScrollTo: (hash) ->
        # Get the scroll destination
        scrollDestination = $("[data-scrollto='#{hash}']").offset().top
        # Get the height of the nav
        navHeight = $('header').height()
        # Subtract the nav height to the scroll position so 
        # that it scrolls the content below the nav
        scrollDestination -= navHeight
        # Just keep scrollin', scrollin', scrollin'
        $('html, body').animate
            scrollTop: scrollDestination
        , scrollDestination * .5