class Homepage

    constructor: ->
        @registerEvents()
        @fixHeight = new FixHeight

    registerEvents: ->
        $(document).ready =>
            @fixHeight.fix($('section.shows-list .show .details'))

        $(window).load =>
            @fixHeight.fix($('section.shows-list .show .details'))

        $(window).resize =>
            @fixHeight.fix($('section.shows-list .show .details'))
