class SellTickets

    constructor: ->
        @fixHeight = new FixHeight
        @registerEvents()

    registerEvents: ->
        $(document).ready =>
            @fixHeight.fix($('section.features .feature .icon .o'))
            @fixHeight.fix($('section.features .feature'))

        $(window).load =>
            @fixHeight.fix($('section.features .feature .icon .o'))
            @fixHeight.fix($('section.features .feature'))

        $(window).resize =>
            @fixHeight.fix($('section.features .feature .icon .o'))
            @fixHeight.fix($('section.features .feature'))

