Vue.directive 'datepicker',
    twoWay: true

    params: ['available', 'options']

    bind: ->
        self = @

        __defaults =
            nextText:      '<i class="fa fa-chevron-right"></i>'
            prevText:      '<i class="fa fa-chevron-left"></i>'
            dateFormat:    'dd/mm/yy'
            yearRange:     "-100:+0"
            beforeShowDay: (date) ->
                if !self.params.available
                    return [true, "", ""]

                formattedDate = moment(date).format('YYYY-MM-DD')

                result = $.grep self.params.available, (e) ->
                    return e.date == formattedDate

                if result.length
                    return [true, "", "Available"]
                else
                    return [false, "", "Unavailable"]

        options = $.extend(true, __defaults, this.params.options)

        $(@el)
            .datepicker(options)
            .on 'change', ->
                self.set(@value)

    update: (value) ->
        $(@el).val(value).trigger('change')

    unbind: ->
        $(@el).off().datepicker('destroy')
