class Modal

    constructor: ->
        @registerGlobalModalEvents()

    registerGlobalModalEvents: ->
        # Vertically center modals on screen
        $(document)
            .off 'show.bs.modal'
            .on 'show.bs.modal', '.modal', @centerModals

        # Update vertical position on resize
        $(window)
            .off 'resize'
            .on 'resize', @centerModals

    # Function to vertically center modals
    centerModals: ->
        $('.modal').each (i) ->
            $clone = $(this).clone().css('display', 'block').appendTo('body')
            top = Math.round(($clone.height() - $clone.find('.modal-content').height()) / 2)
            top = if top > 0 then top else 0
            $clone.remove()
            $(this).find('.modal-content').css("margin-top", top)
