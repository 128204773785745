Vue.directive 'select',

    twoWay: true
    deep:   true
    params: ['options']

    bind: ->
        self = this

        $(@el)
            .select2
                data: @params.options
            .on 'change', ->
                self.set(@value)

    update: (value) ->
        $(@el).val(value).trigger('change')

    unbind: ->
        $(@el).off().select2('destroy')
