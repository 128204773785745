class BookPayment

    constructor: ->
        @registerEvents()
        @registerComponent()

    registerEvents: ->
        @alignOptions()

        $(document).ready =>
            @alignOptions()

        $(window).resize =>
            @alignOptions()

    alignOptions: ->
        min = 0
        $('.radio-options .option label')
            .css { height: '' }
            .each (i, el) ->
                if $(el).outerHeight() > min then min = $(el).outerHeight()
            .css { height: min }

        min = 0
        $('.radio-options .option.small-option label')
            .css { height: '' }
            .each (i, el) ->
                if $(el).outerHeight() > min then min = $(el).outerHeight()
            .css { height: min }

    registerComponent: ->
        @component = new Vue
            el: 'body'

            data:
                formData:   {}
                submitting: false

            ready: ->
                Stripe.setPublishableKey App.stripe.key

            watch:
                'formData.method_type': ->
                    this.$nextTick ->
                        $('[data-card-num]').payment('formatCardNumber')
                        $('[data-card-cvc]').payment('formatCardCVC')
                        $('[data-card-expiry]').payment('formatCardExpiry')

                        Global.YBOTooltips()
                        if this.isParentPay
                            $('#parentPayForm').submit()

            computed:
                isStripe: ->
                    return (this.formData.method_type == 'stripe')

                isParentPay: ->
                    return (this.formData.method_type == 'parentpay')

                canCompleteBooking: ->
                    return (
                        this.formData.method_type and
                        this.formData.method_type != '' and
                        !this.isParentPay
                    )

            methods:
                submitParentPay: ->
                    this.formData.method_type = 'parentpay'

                validateAndSubmit: ->
                    if this.submitting
                        return
                    this.submitting = true
                    if @["#{this.formData.method_type}Handler"]
                        @["#{this.formData.method_type}Handler"]()
                        return

                    # Default handler
                    $xhr = $.ajax
                        url:    App.urls.manual
                        method: 'POST'
                        data:   this.formData

                    $xhr.done (data) ->
                        window.location.href = App.urls.next.replace('_id_', data.booking_id)

                    $xhr.fail =>
                        this.submitting = false

                stripeHandler: ->
                    expiry = $.payment.cardExpiryVal(@formData.expiry)
                    data =
                        number:    @formData.card_num
                        cvc:       @formData.cvv
                        exp_month: expiry.month
                        exp_year:  expiry.year

                    Stripe.card.createToken data, @stripeCallback

                stripeCallback: (status, response) ->
                    if status == 200
                        $xhr = $.ajax
                            url: App.urls.stripe
                            method: 'POST'
                            data:
                                token: response.id

                        $xhr.done (data) ->
                            window.location.href = App.urls.next.replace('_id_', data.booking_id)

                        $xhr.fail =>
                            this.submitting = false

                    else
                        this.submitting = false
                        swal "Error!", response.error.message, "error"

                labelClass: (type) ->
                    return {
                        active: (this.formData.method_type == type)
                    }
