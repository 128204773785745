class BookYourDetails

    constructor: ->
        @registerReadyEvent()
        @registerComponent()
        @registerEvents()

    registerReadyEvent: ->
        window.mapReady = ->
            return

    registerEvents: ->

    registerComponent: ->
        @component = new Vue
            el: '.page-bookyour-details'

            data:
                formData:
                    address:
                        address_1: ''
                        address_2: ''
                        city:      ''
                        county:    ''
                        postcode:  ''

                addressOptions:          {}
                selectedAddress:         {}
                performingAddressLookup: false

            computed:
                addressFieldsShouldShow: ->
                    if this.selectedAddress.address_components
                        return true
                    return false

                canSubmit: ->
                    return !_.isEmpty(this.formData.buying_for)

            watch:
                'selectedAddress': (val) ->
                    if val.address_components
                        this.setAddress(val)

            methods:
                getAddress: ->
                    this.performingAddressLookup = true
                    geocoder = new google.maps.Geocoder()
                    geocoder.geocode({
                        address: this.formData.address.postcode
                        componentRestrictions:
                            country: 'GB'
                    }, this.getAddressResults)

                getSeatingPlans: ->
                    $request = $.ajax
                        url:  App.urls.get_seating_plans
                        type: 'GET'
                    $request.done (data) =>
                        this.seatingPlans = data

                getAddressResults: (results, status) ->
                    this.performingAddressLookup = false
                    if (status == 'OK')
                        this.addressOptions = results

                        this.selectedAddress = this.addressOptions[0]
                    else
                        alert("Geocode was not successful for the following reason: #{status}")

                setAddress: (address) ->
                    that = this
                    this.getAddressComponent('route', (val) ->
                        that.formData.address.address_1 = val
                    )
                    this.getAddressComponent(['locality', 'postal_town'], (val) ->
                        that.formData.address.city = val
                    )
                    this.getAddressComponent('administrative_area_level_2', (val) ->
                        that.formData.address.county = val
                    )

                getAddressComponent: (component, callback = ->) ->
                    if typeof component is 'string'
                        component = [component]

                    $.each this.selectedAddress.address_components, (i, c) ->
                        $.each component, (ii, type) ->
                            if $.inArray(type, c.types) > -1
                                callback(c.long_name)
                                return false
                    return false
