class Contact

    constructor: ->
        @contactForm = new AjaxForm({
            el:     '#contact-form'
            done:   @doneResponse
            fail:   @failResponse
            always: @alwaysResponse
        })

    doneResponse: (data, textStatus, jqXHR) =>
        # Enable the submitted text
        $form = $(@contactForm.data.el)
        $form.closest('.form-container').addClass 'submitted'

    alwaysResponse: (jqXHR, textStatus, errorThrown) =>
        # Reset the form
        $form = $(@contactForm.data.el)
        $submit = $form.find('[type=submit]')

        $submit.html $submit.data('oldText')
        $form.find(':input').attr 'disabled', false
        $form.find('.custom-select').removeClass 'disabled'

    failResponse: (jqXHR, textStatus, errorThrown) ->
        # Get the error from contactform
        $response = $('<div>')
        $response = $response.html(jqXHR.responseJSON.data)
        responseText = $response.find('.span8').text().trim()

        # Show it in an alert
        sweetAlert("Oops...", responseText, "error")
