class ConfirmModal

    constructor: ->
        @registerEvents()

    registerEvents: ->
        $(document).on('submit', 'form[data-confirm]', @showConfirm)
        $(document).on('click', 'a[data-confirm], button[data-confirm]', @showConfirm)

    showConfirm: (e, data = {}) =>
        if data.confirmed
            if customConfirm = @["confirmForTag#{e.target.tagName}"]
                customConfirm(e)
            return
        e.preventDefault()
        swal({
            title:            "Are you sure?"
            text:             $(e.currentTarget).data('confirm')
            type:             "warning"
            showCancelButton: true
        }, (isConfirm) =>
            if isConfirm
                @confirmAction(e)
        )

    confirmAction: (e) ->
        $(e.target).trigger(e.originalEvent.type, { confirmed: true })

    confirmForTagA: (e) ->
        window.location = $(e.target).attr('href')
