class Nav

    constructor: ->
        @registerEvents()

    registerEvents: ->
        @setup()

        $(document).on 'click', '[data-toggle-nav]', (e) =>
            @onToggleNavClick(e)

        $(document).on 'click', '.body-overlay', =>
            @closeNav()

        $(window).on 'resize', =>
            @closeNav()

    # Setup the menu dom
    setup: ->
        $('body').wrapInner '<div class="body-container"/>'
            .append @render()
            .append '<div class="body-overlay" />'
            .wrapInner '<div class="body-overflow" />'

    # Render the actual menu
    render: ->
        @generateNavItems('header .main-nav nav ul')

        navTemplate = '<nav id="mobile-menu"><ul>%n</ul></nav>'
        return navTemplate.replace '%n', (@renderItem item for item in @items).join("")

    # Generate nav items from the actual nav
    generateNavItems: (nav) ->
        @items = []
        # Get the nav from the dom
        $nav = $(nav)
        # Get the items
        $items = $nav.find('a')
        # Loop through the items and add them to the array
        $items.each (i, el) =>
            # Exclude specific items
            if $(el).attr('href') == '#'
                return false
            # Create the menu item
            item = {
                text: $(el).text()
                link: $(el).attr('href')
            }
            # Add the item to the menu array
            @items.push(item)

    # Render single menu item
    renderItem: (item) ->
        if item['icon']
            icon = "<span class=\"icon\"><i class=\"fa #{item['icon']}\"></i></span>"
        else
            icon = ""
        return "<li>
                <a href=\"#{item['link']}\">
                    <span class=\"link-container\">
                        #{item['text']}
                        #{icon}
                    </span>
                </a>
            </li>"

    # Open and close the sliding navigation
    onToggleNavClick: (e) ->
        e.preventDefault()
        if $('body.nav-open').length
            @closeNav()
        else
            @openNav()

    # Open the sliding navigation
    openNav: ->
        $('[data-toggle-nav]').first().addClass 'open'
        $('body').addClass 'nav-open'

    # Close the sliding navigation
    closeNav: ->
        $('[data-toggle-nav]').first().removeClass 'open'
        $('body').removeClass 'nav-open'
